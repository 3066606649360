import MainLogin from '../components/Main-Login.vue'
import MainDashboard from '../components/Main-Dashboard.vue'
import MainLogout from '../components/Main-Logout.vue'
import MainForgotpw from '../components/Main-Forgotpw.vue'
import UserProfile from '../components/User/User-Profile.vue'
import UserSettings from '../components/User/User-Settings.vue'
import UserMain from '../components/User/User-Main.vue'
import { createRouter, createWebHistory } from 'vue-router'
import {
    Bars3Icon,
    CalendarIcon,
    HomeIcon,
    ArrowUpIcon,
    ArrowUpOnSquareIcon,
    MagnifyingGlassCircleIcon,
    MapIcon,
    MegaphoneIcon,
    UserGroupIcon,
    XMarkIcon,
    InboxIcon,
    UsersIcon,
    CogIcon,
    QuestionMarkCircleIcon,
    ArrowLeftOnRectangleIcon,
    ArrowDownTrayIcon,
    EnvelopeIcon,
    BellIcon,
    EnvelopeOpenIcon, MagnifyingGlassIcon
} from '@heroicons/vue/24/outline'


// main Dashboard Standard Routes.
// Please only modify it in the customer config. Please use the Routing und Navigation modifiers, like RouteAdd()
// DO NOT MODIFY!
const routes = [
    { path: '/', component: MainLogin },
    {
        path: '/dashboard',
        name: "dashboard",
        icon: HomeIcon,
        meta: { position: false },
        component: MainDashboard,
        children: [
            {
                path: '',
                component: UserMain,
                name: "Dashboard",
                meta: { transition: 'slide-left', position: false },
            },
            {
                path: 'profile',
                component: UserProfile,
                name: "userprofile",
                meta: { transition: 'slide-left', position: false },
            },
            {
                path: 'settings',
                component: UserSettings,
                name: "Settings",
                meta: { transition: 'slide-left', position: false },
            },
        ],
    },
    { path: '/logout', name: "logout", component: MainLogout, meta: { position: false } },
    { path: '/forgotpasswd', name: "forgortpw", component: MainForgotpw, meta: { position: false } },
]


const CoreRouter = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
})
export default CoreRouter;