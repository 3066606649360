import axios from 'axios'
import 'axios'

const capitalizeFirstLetter = ([first, ...rest], locale = navigator.language) =>
    first === undefined ? '' : first.toLocaleUpperCase(locale) + rest.join('')
    
export {capitalizeFirstLetter}



const genPasswd = async payload => {
    const JWTtoken = localStorage.getItem(process.env.VUE_APP_NEURAXIS_TOKENNAME);
    axios.defaults.headers.common['Authorization'] = `${JWTtoken}`;

    const request = axios.post("/datahub/genpasswd", {clean:payload});

    return request
        .then(result => {
            return result;
        })
        .catch(error => { return Promise.reject(error); });
}
export { genPasswd };

