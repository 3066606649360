<template>
     <form @submit.prevent="handleSubmit">
        <div class="mb-4">
          <label class="block text-gray-400 font-bold mb-2" for="text-input">
            Passwort zum Encodieren eingeben
          </label>
          <input
            v-model="textInput"
            class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="text-input"
            type="text"
            placeholder="Passwort hier eingeben"
          />
        </div>
        <div class="flex justify-start" v-if="textInput.length > 2">
            <main-button size="sm" color="primary" @click="handleSubmit">Passwort generieren</main-button>
        </div>
      </form>
      <div class="m-3" v-if="textInput.length > 2 && submitted == true">
            <code>{{ returnString }}</code>
     </div>
</template>
<script setup>
import { genPasswd } from '@/core/var/tools';
import { onMounted, ref } from 'vue';
import MainButton from './UI/Main-Button.vue';
const textInput = ref('');
const returnString = ref('');
const submitted = ref(false);

async function handleSubmit() {
    await genPasswd(textInput.value).then(res => {
            submitted.value = true;
            returnString.value = res.data;
    });
}
</script>