<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_orders = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const orders = ref()
const sync = ref()

const get_base_data = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sula/get-all-bexio-orders")
        .then(response => {
            orders.value = response.data
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/sula/get-orders")
        .then(response => {
            sync.value = response.data
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}
onMounted(() => {
    get_base_data()
})
const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}
const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const openBexioOrder = (id) => {
    window.open('https://office.bexio.com/index.php/kb_order/show/id/' + String(id) + '#invoices','_blank')
}

const openWooCommerceOrder = (id) => {
    window.open('https://sulaworld.com/wp-admin/post.php?post=' + String(get_woocommerce_id(id)) + '&action=edit','_blank')
}

const get_woocommerce_id = async (id) => {
    for (let i = 0; i < sync.value.length; i++) {
        if (sync.value[i].bx_id === id) {
            return sync.value[i].wc_id
        }
    }
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ConfirmDialog></ConfirmDialog>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div class="col-12 mb-2">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2" style="width: 300px">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_orders['global'].value" placeholder="Suche" />
                    </span>
                </template>
                <template #end>
                    <Button type="button" icon="pi pi-cog" aria-controls="overlay_menu" class="p-button-rounded bg-blue-500" />
                </template>
            </Toolbar>
        </div>
        <DataTable v-model:filters="filter_orders" :value="orders" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true"  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column field="id" header="Auftrag" sortable>
                <template #body="slotProps">
                    <Avatar icon="pi pi-file" class="mr-2" style="backgroundColor:var(--primary-500); color:var(--surface-0)"  shape="circle" />
                    {{slotProps.data.id}} - {{slotProps.data.firstname}} {{slotProps.data.lastname}} ({{slotProps.data.customer_nr}})
                </template>
            </Column>
            <Column header="Datum" sortable>
                <template #body="slotProps">
                    {{format_swiss_date(slotProps.data.order_date)}}
                </template>
            </Column>
            <Column field="lastname" hidden></Column>
            <Column field="firstname" hidden></Column>
            <Column field="customer_nr" hidden></Column>
            <Column field="title" header="Betrag" sortable>
                <template #body="slotProps">
                    {{format_currency(slotProps.data.total)}}
                </template>
            </Column>
            <Column field="id" header="">
                <template #body="slotProps">
                    <Button label="Bexio" @click="openBexioOrder(slotProps.data.bexio_order_id)" class="mr-2 p-button w-auto" style="background-color: #0d2f3b; color: #bbdc00; border: 1px solid #bbdc00" icon="pi pi-external-link" />
                    <Button label="WooCommerce" @click="openWooCommerceOrder(slotProps.data.bexio_order_id)" class="mr-2 p-button w-auto" style="background-color: #9B5C8F; color: #FFF; border: 1px solid #9B5C8F" icon="pi pi-external-link" />
                </template>
            </Column>
        </DataTable>
    </BlockUI>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
</template>